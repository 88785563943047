// Home page component
function NoPage() {
	return (
		<div className="App-component" id="NoPage">
			<h3>404 Page Not Found</h3>
				<p>
					Looks like you have found a page that does not exist! please navigate back to the home page!
				</p>
		</div>
	);
}

export default NoPage;
