function Thanks() {
	return (
		<>
		<div className="Page" id="thanks">
			<div className="Flex-gap">
			</div>
			<div>
				<h1>Thank you!</h1>
				<p>
					Your message has been sent.
					<br />
					I'll get back to you as soon as I can.
				</p>
			</div>
			<div className="Flex-gap">
			</div>
		</div>
		</>
	);
}
export default Thanks;
